<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Contract.Create.Title") }}
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <h6 class="title is-6">{{ $t("Interface.Contract.Company.Title") }}</h6>
            <div class="columns is-multiline">
                <div class="column is-full">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        mode="aggressive"
                        tag="div"
                        rules="required"
                        class="block"
                        name="Type"
                    >
                        <b-radio v-model="Company.Type" native-value="owner">
                            {{ $t("Interface.Contract.Company.TypeOwner") }}
                        </b-radio>
                        <b-radio v-model="Company.Type" native-value="agent">
                            {{ $t("Interface.Contract.Company.TypeAgent") }}
                        </b-radio>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.FullName"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.FullName')"
                                type="text"
                                data-cy="FullName"
                                required
                                @input="Company.FullName = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.FullName") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.Kpp"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.KPP')"
                                type="text"
                                data-cy="Kpp"
                                required
                                @input="Company.Kpp = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.State") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.Ogrn"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.OGRN')"
                                type="text"
                                data-cy="Ogrn"
                                required
                                @input="Company.Ogrn = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.TinEin") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.Address"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.Address')"
                                type="text"
                                data-cy="Address"
                                required
                                @input="Company.Address = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.Address") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.ManagerName"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.ManagerName')"
                                type="text"
                                data-cy="ManagerName"
                                required
                                @input="Company.ManagerName = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.ManagerName") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.ManagerPosition"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Company.ManagerPosition')"
                                type="text"
                                data-cy="ManagerPosition"
                                required
                                @input="Company.ManagerPosition = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Company.ManagerPosition") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
            </div>
            <h6 class="title is-6">{{ $t("Interface.Contract.Bank.Title") }}</h6>
            <div class="columns is-multiline">
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.Bic"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Bank.BIC')"
                                type="text"
                                data-cy="Bic"
                                required
                                @input="Company.Bic = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Bank.BIC") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Company.BankAccount"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Contract.Bank.RS')"
                                type="text"
                                data-cy="BankAccount"
                                required
                                @input="Company.BankAccount = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.Contract.Bank.RS") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
            </div>
            <button
                type="submit"
                class="button is-theme is-fullwidth"
                :disabled="invalid"
                @click.prevent="handleSubmit(createCompany)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import { COMPANY_CREATE_REQUEST } from "@/store/actions/contract"

export default {
    name: "ContractCreateRussia",
    mixins: [LoadingState],
    data() {
        return {
            Company: {
                Country: "us",
                FullName: null,
                Kpp: null,
                Ogrn: null,
                Address: null,
                ManagerPosition: null,
                ManagerName: null,
                Bic: null,
                BankAccount: null,
                Type: null,
                ShortName: 0,
                Registered: new Date(-2208988800 * 1000).toISOString(),
                Inn: 0,
                ManagerCredentials: 0,
                BankName: 0,
                BankCity: 0,
                CorrespondentBankAccount: 0
            }
        }
    },
    mounted() {
        if (this.$store.getters.IS_COMPANY_CREATED) this.$router.push({ name: "Contract" })
    },
    methods: {
        createCompany() {
            this.switchLoading()
            this.$store.dispatch(COMPANY_CREATE_REQUEST, this.Company)
                .then(() => {
                    const routeData = this.$router.resolve({ name: `Contract-File-us-${this.Company.Type}` })
                    window.open(routeData.href, "_blank")
                    this.$router.push({ name: "Contract" })
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Contract.Create.Title")
        }
    }
}
</script>
